import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiServiceOptions, ApiResponseSchema } from "../models";

let session_token = undefined;
// TODO: try launching localtunnel into development when the first request is made :()
function makeAuthConfig(config?: AxiosRequestConfig) {
  if (!config) config = {};
  if (!session_token) {
    if (config.withCredentials === undefined || config.withCredentials === null) config.withCredentials = true;
  } else {
    if (!config.headers) config.headers = {};
    if (!config.headers.Authorization) config.headers.Authorization = `Bearer ${session_token}`;
  }
  return config;
}
function checkURL(url: string) {
  if (url === undefined || url === null) {
    throw new Error("api endpoint URL cannot be null or undefined!");
  }
  const last_slash_idx = url.lastIndexOf("/");
  const last_part_of_string = url.substring(last_slash_idx + 1);
  if (last_part_of_string.indexOf("?") < 0) {
    if (!url.endsWith("/")) url += "/";
  }
  return url;
}
export function createApiInstance(options: ApiServiceOptions) {
  //
  let _axios = _initInstance(options);
  // _initRequestUse(_axios, options);
  // _initResponseUse(_axios, options, () => token);
  return {
    setSessionToken(token: string) {
      session_token = token;
    },
    preCheckErrors(...cases: { case: boolean; message: string }[]): boolean {
      let case_occurred = false;
      cases.forEach((c) => {
        if (c.case) {
          // store.alert.send({ type: "error", message: c.message });
          case_occurred = true;
        }
      });
      return case_occurred;
    },
    // TODO: move canBypassErr,decodeError and catchErr to the setup part of the services
    canBypassErr(err: AxiosError) {
      const status = err?.response?.data?.["status"];
      if (!status) return undefined;
      const res = {
        msg: undefined as string,
        err_msg: undefined as string,
        status,
      };
      if (status >= 100 && status < 200) {
        if (status !== 100) res.err_msg = this.decodeError(err, "TOTP Generic Error!");
        res.msg = "TOTP code is required for your account.."; // you may use an app like Google Authenticator to get one
      }

      if (res.msg) {
        // if (status % 100 !== 0) res.err_msg = decode_error(err, "");
        return res;
      }
      return undefined;
    },
    decodeError(err: AxiosError, instead: string) {
      const rec_err = err?.response?.data as any as any;
      instead += "*";
      if (rec_err) {
        const { detail, details, error, errors, password, email } = rec_err;
        let another_one = undefined;
        if (details && typeof details === "object") {
          Object.entries(details).every(([k, v]) => {
            another_one = `${String(k)}: ${String(v)}`;
            return false; // just break on first value
          });
        }
        const final_error_msg = another_one || detail || details || error || errors || password || email || instead;
        return typeof final_error_msg === typeof [] ? final_error_msg[0] || instead : final_error_msg || instead;
      }
      return instead;
    },

    catchErr(err: any, instead: string) {
      const message = this.decodeError(err, instead);
      // store.alert.send({ type: "error", message });
      return Promise.reject(message);
    },
    get<T = any>(url: string, config?: AxiosRequestConfig) {
      url = checkURL(url);
      return _axios.get<T>(url, config);
    },
    getAuth<T = any>(url: string, config?: AxiosRequestConfig) {
      config = makeAuthConfig(config);
      return this.get<T>(url, config);
    },
    post<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkURL(url);
      return _axios.post<T>(url, data, config);
    },
    postAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      config = makeAuthConfig(config);
      return this.post<T>(url, data, config);
    },
    put<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkURL(url);
      return _axios.put<T>(url, data, config);
    },
    putAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      config = makeAuthConfig(config);
      return this.put<T>(url, data, config);
    },
    patch<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      url = checkURL(url);
      return _axios.patch<T>(url, data, config);
    },
    patchAuth<T = any>(url: string, data: unknown, config?: AxiosRequestConfig) {
      config = makeAuthConfig(config);
      return this.patch<T>(url, data, config);
    },
    del<T = any>(url: string, config?: AxiosRequestConfig) {
      url = checkURL(url);
      return _axios.delete<T>(url, config);
    },
    delAuth<T = any>(url: string, config?: AxiosRequestConfig) {
      config = makeAuthConfig(config);
      return this.del<T>(url, config);
    },
  };
}

function _initInstance(options: ApiServiceOptions) {
  return axios.create({
    baseURL: options.baseUrl,
    // timeout: 3000,
    headers: {
      // "accept": "*/*",
      "Content-Type": "application/json",
      // "Set-Cookie": "frontend=sad; HTTPOnly; Secure;",
      // "Cross-Origin-Opener-Policy": "same-origin",
      // "X-Content-Type-Options": "nosniff"
    },
  });
}

// function _initRequestUse(instance: AxiosInstance, options: ApiServiceOptions<any>) {
// 	instance.interceptors.request.use(
// 		(config) => {
// 			if (options?.token) {
// 				const token = options.token?.get?.();
// 				if (token) {
// 					config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
// 					// config.headers["x-access-token"] = token; // for Node.js Express back-end
// 				}
// 			}

// 			return config;
// 		},
// 		(error) => {
// 			return Promise.reject(error);
// 		},
// 	);
// }

function _initResponseUse(instance: AxiosInstance, options: ApiServiceOptions, getToken: () => any) {
  instance.interceptors.response.use(
    (res) => {
      return Promise.resolve(res);
    },
    async (err) => {
      const originalConfig = err.config;
      // const refresh = options.getToken?.(getToken?.());
      // if (refresh) {
      //   const token_endpoint = options.endpoints.tokenRefresh;

      //   if (originalConfig.url !== token_endpoint && err.response) {
      //     // helper.log("reached instance.response.expired :: ", err);
      //     // Access Token was expired
      //     if (err.response.status === 401 && !originalConfig._retry) {
      //       // log.http(false, "access token expired :: ", err);
      //       originalConfig._retry = true;

      //       try {
      //         // log.warn("refreshing access token :: ", token_cookie);
      //         const rs = await instance.post(token_endpoint, { refresh: refresh.refresh });
      //         if (options.onRefreshed) {
      //           await Promise.resolve(options.onRefreshed(rs));
      //         }
      //         // store.cookie.set("token", newToken);
      //         // const user = store.user.default.data.first_name.ornull?.get({noproxy: true})
      //         // store.alert.send({ type: "success", message: "session refreshed!" });
      //         return instance(originalConfig);
      //       } catch (_error) {
      //         // log.error("cannot refresh access token, logging out");
      //         // store.alert.send({
      //         // 	type: "error",
      //         // 	message: "there is an issue with your session token, please log out and back in to resolve it.*",
      //         // });
      //         return Promise.reject(_error);
      //       }
      //     }
      //   }
      // }

      return Promise.reject(err);
    }
  );
}
